import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import ErrorBoundary from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';

import DbaLoader from '@app/components/UI/DbaLoader/DbaLoader';
import { ApplicationState } from '@app/store';
import SecureRouteWrapper from '@app/components/Routes/SecureRoute/SecureRouteWrapper';

const params = {
	fallback: <DbaLoader />,
};

const DashboardLayout = loadable(() => loadableDelay(/* webpackChunkName: "DashboardLayout" */
	import('@app/components/UI/DashboardLayout/DashboardLayout'),
), params);

interface Props extends RouteProps {
	component: any;
	redirectPath?: string;
	title?: string;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', title, ...rest
}) => {
	const user = useSelector((state: ApplicationState) => state.login.user, shallowEqual);
	return <Route
		{...rest}
		render={(props) => (user
			? (
				<SecureRouteWrapper path={rest.path as string}>
					<DashboardLayout>
						{title && <Helmet>
							<title>{title}</title>
						</Helmet>}
						<ErrorBoundary>
							<Component {...props} />
						</ErrorBoundary>
					</DashboardLayout>
				</SecureRouteWrapper>
			) : <Redirect to={redirectPath} />)}
	/>;
};

export default DashboardRoute;
