import { ReducersMapObject } from 'redux';

import * as Item from '@common/react/store/Item';
import { BaseApplicationState, BaseAppThunkAction, baseReducers } from '@common/react/store';
import { VideoChatState, getReducer as getVideoChatReducer } from '@common/react/store/VideoChat';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';
import { ItemsState as ItemsProviderStoreState, getReducer as getIPStoreReducer } from '@common/react/store/ItemsProviderStore';

import { Routine } from '@commonTuna/react/objects/Routine';
import { CompanyFeature } from '@commonTuna/react/objects/CompanyFeature';

import { User } from '@app/objects/User';
import { BuildData } from '@app/objects/BuildData';
import { SecureRoutesPropsPagesMap } from '@app/components/Routes/SecureRoute/SecureRoutesPropsPagesMap';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	buildData: Item.ItemState<BuildData>;

	companyFeatures: ItemsProviderStoreState<CompanyFeature>;

	// page: Item.ItemState<Page>;
	// pages: Items.ItemsState<Page>;

	routesPropsPagesMap: Item.ItemState<SecureRoutesPropsPagesMap>;

	routine: Item.ItemState<Routine>;

	serverPage: PageItemState<any>;

	videoChat: VideoChatState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	buildData: Item.getReducer<BuildData>('buildData'),

	companyFeatures: getIPStoreReducer<CompanyFeature>('companyFeatures'),

	// page: Item.getReducer<Page>('page'),
	// pages: Items.getReducer<Page>('pages'),

	routine: Item.getReducer<Routine>('routine'),

	routesPropsPagesMap: Item.getReducer<SecureRoutesPropsPagesMap>('routesPropsPagesMap'),

	serverPage: PageStateReducer<any>('serverPage'),

	videoChat: getVideoChatReducer(),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, User, ApplicationState>
