import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';

import DashboardRoute from '@app/components/Pages/Routes/DashboardRoute';

import Layout from '@app/components/Layout';
import DbaLoader from '@app/components/UI/DbaLoader/DbaLoader';

const params = {
	fallback: <DbaLoader />,
};

const paramsLogo = {
	fallback: <DbaLoader />,
};

/* --------------Admin---------------*/

const Users = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UsersPage" */
		'@app/components/Pages/Admin/Users/Users'
	)), params);
const UserEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserEditorPage" */
		'@app/components/Pages/Admin/Users/UserEditor'
	)), params);
const Pages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */
		'@app/components/Pages/Admin/Page/Pages'
	)), params);
const Companies = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CompaniesPage" */
		'@app/components/Pages/Admin/Companies/Companies'
	)), params);
const CompanyEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CompanyEditorPage" */
		'@app/components/Pages/Admin/Companies/CompanyEditor'
	)), params);
const Clients = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClientsPage" */
		'@app/components/Pages/Admin/Clients/Clients'
	)), params);
const ClientEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClientEditorPage" */
		'@app/components/Pages/Admin/Clients/ClientEditor'
	)), params);
const Ethnicities = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EthnicitiesPage" */
		'@app/components/Pages/Admin/Ethnicities/Ethnicities'
	)), params);
const IcdCodes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "IcdCodesPage" */
		'@app/components/Pages/Admin/Codes/IcdCodes'
	)), params);
const CptCodes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CptCodesPage" */
		'@app/components/Pages/Admin/Codes/CptCodes'
	)), params);
const PayerList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PayersPage" */
		'@app/components/Pages/Admin/Payers/Payers'
	)), params);
const CptModifierList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ModifiersPage" */
		'@app/components/Pages/Admin/CptModifiers/CptModifiers'
	)), params);
const PlaceOfServiceList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PlaceOfServicesPage" */
		'@app/components/Pages/Admin/PlaceOfServices/PlaceOfServices'
	)), params);
const Laboratories = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LaboratoriesPage" */
		'@app/components/Pages/Admin/Laboratories/Laboratories'
	)), params);
const LaboratoryEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LaboratoryEditorPage" */
		'@app/components/Pages/Admin/Laboratories/LaboratoryEditor'
	)), params);
const Routines = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RoutinesPage" */
		'@app/components/Pages/Admin/Routines/Routines'
	)), params);
const RoutineEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RoutineEditorPage" */
		'@app/components/Pages/Admin/Routines/RoutineEditor'
	)), params);
const AnalysisSources = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalysisSourcesPage" */
		'@app/components/Pages/Admin/AnalysisSources/AnalysisSources'
	)), params);
const AnalysisLocations = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalysisLocationsPage" */
		'@app/components/Pages/Admin/AnalysisLocations/AnalysisLocations'
	)), params);
const Certificates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CertificatesPage" */
		'@app/components/Pages/Admin/Certificates/Certificates'
	)), params);
const CertificateEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CertificateEditorPage" */
		'@app/components/Pages/Admin/Certificates/CertificateEditor'
	)), params);
const DrugClassList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DrugClassesPage" */
		'@app/components/Pages/Admin/DrugClasses/DrugClasses'
	)), params);
const Specialties = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialtiesPage" */
		'@app/components/Pages/Admin/Specialties/Specialties'
	)), params);
const GlobalProcedureEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalProcedureEditor" */
		'@app/components/Pages/Admin/GlobalProcedures/GlobalProcedureEditor'
	)), params);
const GlobalPayerList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalReportsPage" */
		'@app/components/Pages/Admin/GlobalPayers/GlobalPayers'
	)), params);
const Languages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LanguagesPage" */
		'@app/components/Pages/Admin/Languages/Languages'
	)), params);
const FaxReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaxReport" */
		'@app/components/Pages/Admin/FaxReport/FaxReport'
	)), params);
const MerchantReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "MerchantReport" */
		'@app/components/Pages/Admin/MerchantReport/MerchantReport'
	)), params);
const SpecialtyEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialtyEditor" */
		'@app/components/Pages/Admin/Specialties/SpecialtyEditor'
	)), params);
const SystemMerchantEditor = loadable(() => loadableDelay(
	import(/* webpackChunkName: "SystemMerchantEditor" */
		'@app/components/Pages/Admin/SystemMerchant/SystemMerchantEditor'
	),
), params);
const Transactions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Transactions" */
		'@app/components/Pages/Admin/Transactions/Transactions'
	)), params);
const TransactionViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TransactionViewer" */
		'@app/components/Pages/Admin/Transactions/TransactionViewer'
	)), params);
const Invoices = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InvoicesPage" */
		'@app/components/Pages/Admin/Invoices/Invoices'
	)), params);
const EmailTemplates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailTemplates" */
		'@app/components/Pages/Admin/EmailTemplates/EmailTemplates'
	)), params);
const PricePlans = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PricePlansPage" */
		'@app/components/Pages/Admin/PricePlans/PricePlans'
	)), params);
const GlobalPayers = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalPayerListPage" */
		'@app/components/Pages/Admin/GlobalPayers/GlobalPayers'
	)), params);
const GlobalProcedures = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalProcedureListPage" */
		'@app/components/Pages/Admin/GlobalProcedures/GlobalProcedures'
	)), params);
const PricePlanEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PricePlanEditorPage" */
		'@app/components/Pages/Admin/PricePlans/PricePlanEditor'
	)), params);
const SmsLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "SmsLogs" */
			'@commonTuna/react/components/Pages/Admin/SmsLogs/SmsLogs'
		)),
	params,
);
const EmailLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "EmailLogs" */
			'@commonTuna/react/components/Pages/Admin/EmailLogs/EmailLogs'
		)),
	params,
);
const InquiryReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InquiryReport" */
		'@app/components/Pages/Admin/InquiryReport/InquiryReport'
	)), params);
const BillingPlans = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillingPlans" */
		'@app/components/Pages/Admin/BillingPlans/BillingPlans'
	)), params);
const BillingPlanEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillingPlanEditor" */
		'@app/components/Pages/Admin/BillingPlans/BillingPlanEditor'
	)), params);
const ColorSets = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ColorSets" */
		'@app/components/Pages/Admin/ColorSets/ColorSets'
	)), params);
const SystemPaymentInquiries = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SystemPaymentInquiriesPage" */
		'@app/components/Pages/Admin/SystemPaymentInquiries/SystemPaymentInquiries'
	)), params);

/* ------------Admin end-------------*/

// const Home = loadable(() =>
// loadableDelay(import(/* webpackChunkName: "HomePage" */ './components/Pages/Home/Home')), params);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */ '@app/components/Pages/Login/Login')), params);
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */ '@app/components/Pages/Recover/Recover')), params);
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ '@app/components/Pages/Confirm/Confirm')), params);
const Register = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */ '@app/components/Pages/Register/Register')), params);
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfilePage" */ '@app/components/Pages/Profile')), params);
const AccessDenied = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccessDenied" */
		'@common/react/components/Pages/AccessDenied/AccessDenied'
	)), paramsLogo);

export const routes = (<Layout>
	<Switch>
		<Route path="/" component={Login} exact />
		<Route path="/login" component={Login} />
		<Route path="/recover" component={Recover} />
		<Route path="/confirm" component={Confirm} />
		<Route path="/registration" component={Register} />
		<NotFoundRoute
			path="/access-denied"
			title="403 - ACCESS DENIED"
			component={AccessDenied}
			loginRoute={DashboardRoute}
			openRoute={Route}
		/>
		<DashboardRoute path="/user-list/:page?" component={Users} />
		<DashboardRoute path="/user-editor/:id" component={UserEditor} />
		<DashboardRoute path="/page-list/:page?" component={Pages} />
		<DashboardRoute path="/company-list/:page?" component={Companies} title="Companies" />
		<DashboardRoute path="/company-editor/:id" component={CompanyEditor} />
		<DashboardRoute path="/client-list/:page?" component={Clients} />
		<DashboardRoute path="/client-editor/:id" component={ClientEditor} />
		<DashboardRoute path="/ethnicity-list/:page?" component={Ethnicities} title="Ethnicities" />
		<DashboardRoute path="/icd-code-list/:page?" component={IcdCodes} title="Icd-10 Codes" />
		<DashboardRoute path="/cpt-code-list/:page?" component={CptCodes} title="Cpt Codes" />
		<DashboardRoute path="/payer-list/:page?" component={PayerList} title="Payers" />
		<DashboardRoute path="/cpt-modifier-list/:page?" component={CptModifierList} title="Cpt Modifiers" />
		<DashboardRoute path="/place-service-list/:page?" component={PlaceOfServiceList} title="Place Of Services" />
		<DashboardRoute path="/laboratory-list/:page?" component={Laboratories} title="Laboratories" />
		<DashboardRoute path="/laboratory-editor/:id" component={LaboratoryEditor} />
		<DashboardRoute path="/routine-list/:page?" component={Routines} title="Laboratory Categories" />
		<DashboardRoute path="/routine-editor/:id" component={RoutineEditor} />
		<DashboardRoute path="/analysisSource-list/:page?" component={AnalysisSources} title="Analysis Sources" />
		<DashboardRoute path="/analysisLocation-list/:page?" component={AnalysisLocations} title="Analysis Locations" />
		<DashboardRoute path="/certificate-list/:page?" component={Certificates} title="Certificates" />
		<DashboardRoute path="/certificate-editor/:id" component={CertificateEditor} />
		<DashboardRoute path="/profile" component={Profile} />
		<DashboardRoute path="/fax-list" component={FaxReport} />
		<DashboardRoute path="/merchant-report" component={MerchantReport} />
		<DashboardRoute path="/inquiry-report" component={InquiryReport} />
		<DashboardRoute path="/drug-class-list/:page?" component={DrugClassList} title="Drug classes" />
		<DashboardRoute path="/global-payer-list/:page?" component={GlobalPayers} title="Global payers" />
		<DashboardRoute path="/global-procedure-list/:page?" component={GlobalProcedures} title="Global procedures" />
		<DashboardRoute path="/global-procedure-editor/:id?" component={GlobalProcedureEditor} title="Global Procedure Editor" />
		<DashboardRoute path="/specialties" component={Specialties} title="Specialties" />
		<DashboardRoute path="/languages" component={Languages} title="Languages Dictionary" />
		<DashboardRoute path="/specialty-editor/:id" component={SpecialtyEditor} title="Specialty Editor" />
		<DashboardRoute path="/system-merchant" component={SystemMerchantEditor} title="System Merchant" />
		<DashboardRoute path="/transactions/:page?" component={Transactions} title="Transactions" />
		<DashboardRoute path="/email-templates" component={EmailTemplates} title="Email Templates" />
		<DashboardRoute path="/transaction-editor/:id" component={TransactionViewer} title="Transactions" />
		<DashboardRoute path="/invoice-list" component={Invoices} title="Invoices" />
		<DashboardRoute path="/price-plans/:page?" component={PricePlans} title="Price Plans" />
		<DashboardRoute path="/price-plan-editor/:id" component={PricePlanEditor} title="Price Plan Editor" />
		<DashboardRoute path="/smsLogs" component={(props) => <SmsLogs {...props} withServerPage={false} />} title="Sms Logs" />
		<DashboardRoute path="/emailLogs" component={(props) => <EmailLogs {...props} withServerPage={false} />} title="Email Logs" />
		<DashboardRoute path="/billing-plans/:page?" component={BillingPlans} title="Billing Plans" />
		<DashboardRoute path="/color-sets/:page?" component={ColorSets} title="Color Sets" />
		<DashboardRoute path="/billing-plan-editor/:id" component={(props) => <BillingPlanEditor {...props} withTitle />} />
		<DashboardRoute path="/system-payment-inquiry-list/:page?" component={SystemPaymentInquiries} title="System Payment Inquiries" />
		<NotFoundRoute path="*" openRoute={Route} loginRoute={DashboardRoute} />
	</Switch>
</Layout>);
