import React from 'react';

import once from 'lodash/once';

import { Redirect, RouteProps } from 'react-router-dom';

interface ComponentProps extends RouteProps {
	path: string;
	canAccess: (mainPath: string) => boolean;
	children?: any;
}

export interface SecureRoutesProviderContext {
	canAccess: (path: string) => boolean;
}

export const createSecureRoutesProviderContext = once(() => React.createContext({} as SecureRoutesProviderContext));

export const useSecureRoutesProviderContext: () => SecureRoutesProviderContext = () =>
	React.useContext(createSecureRoutesProviderContext());

export const BaseSecureRouteWrapper: React.FC<ComponentProps> = ({
	path,
	children,
	canAccess: propsCanAccess,
}) => {
	const SecureRoutesProviderContext = createSecureRoutesProviderContext();

	const canAccess = (path: string): boolean => {
		const slashIndex = path.lastIndexOf('/');

		const mainPath = path.substring(1, slashIndex > 0 ? slashIndex : path.length);

		return propsCanAccess(mainPath);
	};

	return (
		<SecureRoutesProviderContext.Provider value={{ canAccess }}>
			{canAccess(path) ? children : <Redirect to="/access-denied" />}
		</SecureRoutesProviderContext.Provider>
	);
};

export default BaseSecureRouteWrapper;
