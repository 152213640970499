import React from 'react';

import { RouteProps } from 'react-router-dom';

import { shallowEqual, useSelector } from 'react-redux';

import BaseSecureRouteWrapper from '@commonTuna/react/components/Routes/SecureRoute/BaseSecureRouteWrapper';

import { ApplicationState } from '@app/store';
import { RoutesPropsMap } from '@app/components/Routes/SecureRoute/SecureRoutesPropsPagesMap';
import { UserRole } from '@app/objects/User';

interface ComponentProps extends RouteProps {
	path: string;
	children?: any;
}

export const SecureRouteWrapper: React.FC<ComponentProps> = ({
	path,
	children,
}) => {
	const routesPropsPagesMap = useSelector((state: ApplicationState) => state.routesPropsPagesMap.item, shallowEqual);
	const login = useSelector((state: ApplicationState) => state.login);

	const canRoleAccess = (route: RoutesPropsMap, role: UserRole) =>
		!route.roles || (route.roles.length > 0 && route.roles.includes(role));

	const canAccess = (mainPath: string): boolean => {
		const route = routesPropsPagesMap[mainPath];

		return Boolean(!route || (login.user && (login.user.root
			|| canRoleAccess(route, login.user.role))));
	};

	return (
		<BaseSecureRouteWrapper
			path={path}
			canAccess={canAccess}
		>
			{children}
		</BaseSecureRouteWrapper>
	);
};

export default SecureRouteWrapper;
