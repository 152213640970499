import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.css';

import '@common/react/yupLocaleSettings';
import ErrorBoundary from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import Application from '@common/react/components/Core/Application/Application';

import DbaLoader from '@app/components/UI/DbaLoader/DbaLoader';
import { Init } from '@app/objects/Init';
import { User } from '@app/objects/User';
import { customReduxActions } from '@app/store/customReduxActions';

import '@app/scss/style.scss';

const Layout: React.FC = ({ children }) => {
	const transmutationHandler = () => window.location.replace('/profile');

	return <div className="app-container">
		<ErrorBoundary>
			<LoadingProvider loader={<DbaLoader />}>
				<RequestProvider>
					<Application<User, Init>
						transmutationHandler={transmutationHandler}
						initCustomHandler={customReduxActions}
					>
						{children}
					</Application>
				</RequestProvider>
			</LoadingProvider>
		</ErrorBoundary>
	</div>;
};

export default Layout;
