import * as React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';

import once from 'lodash/once';
import isEmpty from 'lodash/isEmpty';

import Cookies from 'js-cookie';

import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import * as LoginState from '@common/react/store/Login';
import { getSignalR } from '@common/react/utils/configureSignarR';
import useRequest from '@common/react/hooks/useRequest';
import { BuildData } from '@common/react/objects/BuildData';
import BaseHostOptions from '@common/react/store/BaseHostOptions';
import { BaseInit } from '@common/react/objects/BaseInit';
import { Lang } from '@common/typescript/objects/Lang';
import { RequestType } from '@common/react/components/RequestProvider/RequestProvider';

export interface ApplicationContext {
	onTransmutation: () => void;
	getTransmutationFlag: () => boolean;
	getUser: <TUser extends BaseUser>() => TUser;
	updateUser: (data: any, getUser?: (user) => any) => void;
	getHostOptions: <THostOptions extends BaseHostOptions>() => THostOptions;
	getBuildData: <TBuildData extends BuildData>() => TBuildData;
	getLang: () => Lang;
	request: RequestType;
	subscribe: (handle: (notify: any) => void) => () => void;
	checkUserAccess: (roles: Array<number>, props?: any) => boolean;
	memoUntilLogout: (factory: () => unknown, deps?: React.DependencyList | undefined) => any;
	subscribeUntilLogout: (handle: (notify: any) => void, deps?: React.DependencyList | undefined) => void;
}

interface Props<TUser extends BaseUser, TInit extends BaseInit<TUser>> {
	children?: React.ReactNode;
	initCustomHandler?: ((dispatch: any, init: TInit) => void);
	transmutationHandler?: () => void;
	checkUserAccess?: (user: TUser, roles: Array<number>, props?: any) => boolean;
	/*
	* must be constant (affects the number of hooks)
	* */
	withoutSignalR?: boolean;
}

export const subscribe = (handle: (notify: any) => void) => () => {
	getSignalR().then((connection) => connection.on('handle', handle));

	return () => {
		getSignalR().then((connection) => connection.off('handle', handle));
	};
};

export const createApplicationContext = once(() => React.createContext({} as ApplicationContext));

export const useApplicationContext: () => ApplicationContext = () => {
	const context : ApplicationContext = React.useContext(createApplicationContext());

	if (isEmpty(context)) throw 'Need Application context!';

	return context;
};

type LoginActions = LoginState.LoginActionCreators<BaseUser, BaseApplicationState<BaseUser>>;

const Application: <TUser extends BaseUser, TInit extends BaseInit<TUser>>(
	p: Props<TUser, TInit>,
) => React.ReactElement<TUser> = <TUser extends BaseUser, TInit extends BaseInit<TUser>>({
	children,
	initCustomHandler,
	transmutationHandler,
	checkUserAccess,
	withoutSignalR,
}: Props<TUser, TInit>) => {
	const ApplicationContext = createApplicationContext();

	const onTransmutation = transmutationHandler ?? (() => window.location.replace('/dashboard'));
	const getUser = <TUser extends BaseUser>() =>
		useSelector((state: BaseApplicationState<TUser>) => state.login.user! as TUser, shallowEqual);
	const getHostOptions = <THostOptions extends BaseHostOptions>() =>
		useSelector((state: any) => (state.hostOptions.item), shallowEqual) as THostOptions;
	const getBuildData = <TBuildData extends BuildData>() =>
		useSelector((state: any) => (state.buildData.item), shallowEqual) as TBuildData;
	const getTransmutationFlag = <TUser extends BaseUser>() =>
		useSelector((state: BaseApplicationState<TUser>) => state.login.transmuted, shallowEqual);
	const getLang: () => Lang = () => useSelector((state: BaseApplicationState<any>) => state.login.lang, shallowEqual);
	const request = useRequest();

	const user = getUser() as TUser;
	const _checkUserAccess = (roles, props) => (checkUserAccess && checkUserAccess(user!, roles, props)) || false;
	const dispatch = useDispatch();
	const loginActions: LoginActions = bindActionCreators(LoginState.getActionCreators(), dispatch);
	const updateUser = loginActions.updateUser;

	const memoUntilLogout = (factory: () => unknown, deps?: React.DependencyList | undefined) =>
		React.useMemo(factory, deps ? [user].concat(deps) : [user]);

	const subscribeUntilLogout = (handler: (notify: any) => void, deps?: React.DependencyList | undefined) =>
		React.useEffect(subscribe(handler), deps ? [user].concat(deps) : [user]);

	const handleSession = <TInit extends BaseInit<TUser>>(data: TInit) => {
		const sessionGuid = data.guid as string;
		// It is necessary to put a cookie on the frontend at the time of receiving a notification
		// if the connection to the signalR has been lost (when login)
		Cookies.set('session', sessionGuid);
		loginActions.setUserAndSession(data.user as BaseUser, sessionGuid);
	};

	const baseHandler = (notification) => {
		switch (notification.objectType) {
			case 'Init':
				// it is necessary to reload all pages of the user if he transmuted into another
				if (user && notification.data?.user?.id !== user.id) {
					onTransmutation();
					break;
				}
				!user && initCustomHandler && initCustomHandler(dispatch, notification.data);
				handleSession(notification.data);
				break;
			case 'UserSession':
				handleSession(notification.data);
				break;
			default: {
				break;
			}
		}
	};

	const handle = (notification) => {
		switch (notification.objectType) {
			case 'NotificationUser':
				if (user && notification.service && user.unviewedNotificationsCount > 0) {
					updateUser({ unviewedNotificationsCount: user.unviewedNotificationsCount - 1 });
				}
				break;
			case 'ReadAllNotification':
				if (notification.service) {
					updateUser({ unviewedNotificationsCount: 0 });
				}
				break;
			case 'UserStateNotificationObject':
				if (user && notification.service) {
					const status = notification.data.userStatuses.find((q) => q.id === user.id)?.status;
					status && updateUser({ status: status.status });
				}
				break;
			default:
				if (user && !notification.service) {
					updateUser({ unviewedNotificationsCount: user.unviewedNotificationsCount + 1 });
				}
				break;
		}
	};

	if (!withoutSignalR) {
		React.useEffect(subscribe(baseHandler), []);
		subscribeUntilLogout(handle);
	}

	return <>
		<ApplicationContext.Provider value={{
			onTransmutation,
			getTransmutationFlag,
			getUser,
			getHostOptions,
			getBuildData,
			getLang,
			request,
			subscribe,
			updateUser,
			memoUntilLogout,
			subscribeUntilLogout,
			checkUserAccess: _checkUserAccess,
		}}
		>
			{children}
		</ApplicationContext.Provider>
	</>;
};

interface AfterLogoutProps {
	callback: () => void
}

export const AfterLogout: React.FC<AfterLogoutProps> = ({
	callback,
}) => {
	const { getUser } = useApplicationContext();
	const user = getUser();

	React.useEffect(() => {
		user == null && callback && callback();
	}, [user]);

	return <></>;
};

export default Application;
