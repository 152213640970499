import { Reducer, Action } from 'redux';

import { NullableProps } from '@common/react/objects/NullableProps';

export enum TypeKeys {
	UPDATE = 'UPDATE',
	SETVISIBILITY = 'SET_VISIBILITY',
	SETISCHATTING = 'SET_IS_CHATTING',
	SETTOKEN = 'SET_TOKEN',
	CLEAR = 'CLEAR',
}

export interface VideoChatState {
	isSupport: boolean;
	isChatting: boolean;
	visibility: boolean;
	roomIdentifier?: string;
	roomName?: string;
	avatar?: string;
	preventCameraStart?: boolean;
	token: string | null;
	hasError?: boolean;
}

export interface UpdateAction {
	type: TypeKeys.UPDATE;
	data: NullableProps<VideoChatState>;
}

export interface SetVisibilityAction {
	type: TypeKeys.SETVISIBILITY;
	value: boolean;
}

export interface SetTokenAction {
	type: TypeKeys.SETTOKEN;
	value: string;
}

export interface SetIsChattingAction {
	type: TypeKeys.SETISCHATTING;
	value: boolean;
}

export interface ClearAction {
	type: TypeKeys.CLEAR;
}

type KnownTypeVideoChatActions = UpdateAction | SetVisibilityAction | SetTokenAction | SetIsChattingAction | ClearAction;

const initialState: VideoChatState = {
	isSupport: false,
	isChatting: false,
	visibility: false,
	token: null,
};

export function getReducer(): Reducer<VideoChatState> {
	return (state: VideoChatState = initialState, incomingAction: Action) => {
		const action = incomingAction as KnownTypeVideoChatActions;

		switch (action.type) {
			case TypeKeys.UPDATE:
				return { ...state, ...action.data as VideoChatState };
			case TypeKeys.SETVISIBILITY:
				return { ...state, visibility: action.value };
			case TypeKeys.SETISCHATTING:
				return { ...state, isChatting: action.value };
			case TypeKeys.SETTOKEN:
				return { ...state, token: action.value };
			case TypeKeys.CLEAR:
				return initialState;
			default:
				const exhaustiveCheck: never = action;
		}

		return state;
	};
}
